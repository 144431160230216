.pageHeadingWrapper {
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 0;
}

.pageHeading {
  font-size: 43px;
  letter-spacing: 1px;
  color: var(--chakra-colors-brand-white);
  text-transform: uppercase;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .pageHeadingWrapper {
    padding: 20px;
  }

  .pageHeading {
    font-size: 25px;
  }
}
