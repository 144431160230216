.footer {
  background: rgb(12, 17, 21) !important;
  font-size: 14px;
  color: var(--chakra-colors-brand-white);
}

.footer li,
.footer li a {
  color: var(--chakra-colors-brand-white);
  font-size: 14px;
  line-height: 22px;
}

.footer p {
  font-size: 14px;
  line-height: 22px;
  color: var(--chakra-colors-brand-white);
}

.footerWrapper {
  /* background: rgb(12, 17, 21) !important; */
  /* padding: 40px 20px !important; */
  color: var(--chakra-colors-brand-white);

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(3, 1fr); */
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.footerColumn1 {
  text-align: left;
  order: 1;
}

.footerColumn2 {
  text-align: center;
  order: 2;
}

.footerColumn3 {
  text-align: right;
  order: 3;
}

/* Media query to change grid settings for smaller screens */
@media (max-width: 768px) {
  .footerWrapper {
    grid-template-columns: 1fr; /* Single column layout */
    grid-row-gap: 20px; /* Add some spacing between the stacked divs */
  }

  .footerColumn {
    text-align: center;
  }

  .footerColumn2 {
    order: 3;
  }

  .footerColumn3 {
    order: 2;
  }
}

/* .footer__container {
  padding-top: 25px;
  padding-bottom: 25px;
} */
/* 
@media only screen and (min-width: 769px) {
  .container {
    padding: 45px;
  }
}

@media only screen and (min-width: 1025px) {
  .container {
    padding: 75px;
  }
} */
