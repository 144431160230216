.mobileMenu {
  /* position: fixed; */
  /* top: 0;
  right: 0;
  width: 100%;
  height: 0; */
  /* overflow: hidden; */
  transition: height 0.3s ease;
  background-color: #171717; /* Set your desired background color */
  z-index: 9;
  position: fixed;
  top: 0;
  width: 100%;
  /* height: 199px; */
}

@media only screen and (min-width: 769px) {
  .mobileMenu {
    display: none;
  }
}

.menuBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
}

.logo {
  /* width: 100px; */
  height: 50px;
  width: auto;
}

.menuIcon {
  cursor: pointer;
  padding: 15px;
}

.bar {
  width: 30px;
  height: 3px;
  background-color: #ffffff; /* Set your desired menu icon color */
  margin: 5px 0;
  transition: transform 0.3s ease;
}

.mobileMenuOpen .bar1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.mobileMenuOpen .bar2 {
  opacity: 0;
}

.mobileMenuOpen .bar3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* .menu-content {
  padding: 20px;
} */

.menuContent {
  /* padding: 20px; */
  /* color: #ffffff;
  text-align: center;
  height: 0;
  overflow: hidden;
  position: fixed;
  top: 89px;
  transition: height 0.3s ease; */
  /* padding: 20px; */
  color: #ffffff;
  text-align: center;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: 89px;
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease;
  background: #171717;
  width: 100%;
  z-index: 9;
}

.menuContentOpen {
  /* height: 300px;
  width: 100%;
  background: pink; */
  max-height: 300px; /* Adjust this value as needed */
  opacity: 1;
  width: 100%;
  background: #171717;
}

.smobileMenu {
  /* position: fixed; */
  /* top: 0;
  right: 0;
  width: 100%;
  height: 0; */
  /* overflow: hidden; */
  transition: height 0.3s ease;
  background-color: #171717; /* Set your desired background color */
  z-index: 9;
  /* height: 199px; */
}

.menu-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-content li {
  padding: 10px;
}

.menuInner {
  padding-top: 20px;
  padding-bottom: 20px;
}

.navItem {
  padding: 10px;
}

.navItem:hover {
  background: #ffffff;
  color: #171717;
}

.cta {
  margin: 20px;
}

/* Header Class name  */

.dark {
  /* margin-bottom: 0; */
  background: var(--chakra-colors-brand-black);
  color: var(--chakra-colors-brand-white);
}

.dark button {
  background-image: unset !important;
  background: var(--chakra-colors-brand-white);
  border: 1px solid var(--chakra-colors-brand-white);
  color: var(--chakra-colors-brand-black);
}

.dark button:hover {
  background: var(--chakra-colors-brand-black);
  border: 1px solid var(--chakra-colors-brand-white);
  color: var(--chakra-colors-brand-white);
}

.light {
  /* margin-bottom: 0; */
  background: var(--chakra-colors-brand-white);
  color: var(--chakra-colors-brand-black);
}

.light .bar {
  background-color: var(--chakra-colors-brand-black); /* Set your desired menu icon color */
}

.light .menuContent {
  background: var(--chakra-colors-brand-white);
  color: var(--chakra-colors-brand-black);
}

.light .navItem:hover {
  background: var(--chakra-colors-brand-black);
  color: var(--chakra-colors-brand-white);
}

.light button {
  background-image: unset !important;
  background: var(--chakra-colors-brand-black);
  border: 1px solid var(--chakra-colors-brand-black);
  color: var(--chakra-colors-brand-white);
}

.light button:hover {
  background: var(--chakra-colors-brand-white);
  border: 1px solid var(--chakra-colors-brand-black);
  color: var(--chakra-colors-brand-black);
}
