.headerWrapper {
  width: 100%;
  padding: 30px;
  z-index: 9;
  background: var(--chakra-colors-brand-black);
  color: var(--chakra-colors-brand-white);
}

.headerWrapper button {
  background-image: unset !important;
  background: var(--chakra-colors-brand-white);
  border: 1px solid var(--chakra-colors-brand-white);
  color: var(--chakra-colors-brand-black);
}

.dark {
  margin-bottom: 0;
  background: #171717;
  color: var(--chakra-colors-brand-white);
}

.dark button {
  background-image: unset !important;
  background: var(--chakra-colors-brand-white);
  border: 1px solid var(--chakra-colors-brand-white);
  color: var(--chakra-colors-brand-black);
}

.dark button:hover {
  background: var(--chakra-colors-brand-black);
  border: 1px solid var(--chakra-colors-brand-white);
  color: var(--chakra-colors-brand-white);
}

.light {
  margin-bottom: 0;
  background: var(--chakra-colors-brand-white);
  color: var(--chakra-colors-brand-black);
}

.light button {
  background-image: unset !important;
  background: var(--chakra-colors-brand-black);
  border: 1px solid var(--chakra-colors-brand-black);
  color: var(--chakra-colors-brand-white);
}

.light button:hover {
  background: var(--chakra-colors-brand-white);
  border: 1px solid var(--chakra-colors-brand-black);
  color: var(--chakra-colors-brand-black);
}

.transparent {
  color: white;
  background: transparent;
  position: absolute;
  width: 100%;
  z-index: 9;
  margin-bottom: -105px;
}

.transparent button {
  background-image: unset !important;
  background: #0000004f;
  border: 1px solid var(--chakra-colors-brand-white);
  color: var(--chakra-colors-brand-white);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 100px;
}

.navList {
  display: flex;
  align-items: center;
}

.navItem {
  margin-right: 25px;
  text-transform: uppercase;
  font-family: var(--chakra-fonts-body);
  font-weight: 600;
  font-size: 14px;
  color: var(--chakra-colors-grey-100);
}

.light .navItem {
  color: #5d5d5d !important;
}

.light .navItem.linkActive {
  color: black !important;
}

.navItem:last-child {
  margin-right: 0;
}

.navItem:first-child {
  color: var(--chakra-colors-grey-100);
}

.navItem.linkActive {
  color: white !important;
}

/* .navContainer {
  display: none;
} */

/* .header {
  @media only screen and (max-width: 768px) {
    display: none;
  }
} */

@media only screen and (max-width: 768px) {
  .headerWrapper {
    display: none;
  }
}
